/*
  Theme and global variables
*/

html {
  overflow-y: scroll;

  --game-spacing: 32px;
  --header-height: 4rem;

  --color-success: hsl(150deg 70% 30%);
  --color-warning: hsl(50deg 100% 30%);
  --color-error: hsl(0deg 70% 45%);
  --color-gray-100: hsl(0deg 0% 10%);
  --color-gray-300: hsl(0deg 0% 25%);
  --color-gray-500: hsl(0deg 0% 50%);
  --color-gray-700: hsl(0deg 0% 75%);
  --color-gray-900: hsl(0deg 0% 90%);
}

/*
    Custom styles
  */
body {
  font-family: sans-serif;
}

@media (max-height: 600px) {
  body {
    --game-spacing: 8px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

header {
  display: flex;
  height: var(--header-height);
  border-bottom: 1px solid var(--color-gray-700);
  border-top: 1px solid var(--color-gray-700);
  color: var(--color-gray-300);
}

header .side {
 
  display: grid;
  place-content: center;
}

h1 {
  flex: 1;
  font-size: 2rem;
  line-height: var(--header-height);
  text-align: center;
}

@media (max-width: 25rem) {
  h1 {
    font-size: 1.25rem;
  }
}

.game-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--game-spacing);
  padding-top: 16px;
  padding-bottom: var(--game-spacing);
  padding-left: var(--game-spacing);
  padding-right: var(--game-spacing);
  margin: 0 auto;
  min-width: max(300px, 55vh, 40%);
  max-width: min(500px, 58vh, 100%);
}

.banner {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 32px;
  text-align: center;
  animation: slideUp 750ms cubic-bezier(0, 0.72, 0.24, 1.02);
  border-radius: 4px 4px 0px 0px;
  will-change: transform;
}

.happy.banner {
  background: var(--color-success);
  color: white;
}
.sad.banner {
  background: var(--color-error);
  color: white;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

/*
    Keyframe animations
  */
@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0%);
  }
}
.help-emojis {
  display: flex;
  flex-direction: column;
  font-weight: bold;
}
.help-arrow {
  height: 2.2rem;
  left: 2.6rem;
  bottom: 1.4rem;
  position: absolute;
}
.help-emoji.group-0 {
  background-color: var(--group-0-color);
}
.help-emoji {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  border-radius: 2px;
  margin-bottom: 1.5px;
}
.help-emoji.group-1 {
  background-color: var(--group-1-color);

 
}.help-emoji.group-2 {
  background-color: var(--group-2-color);

}.help-emoji.group-3 {
  background-color: var(--group-3-color);
}
:root {
  --yellow: #f9df6d;
  --green: #a0c35a;
  --blue: #b0c4ef;
  --purple: #ba81c5;
  --group-0-color: var(--yellow);
  --group-1-color: var(--green);
  --group-2-color: var(--blue);
  --group-3-color: var(--purple);
  --red: rgb(255, 103, 103);
  --orange: #f8a765;
  --main-game-color: #b4a8ff;
  --selected-color: #5a594e;
  --incorrect-color: rgb(90 89 78 / 75%);
  --light-grey: rgb(212, 212, 212);
  --grey: #e2e2e2;
  --ui-grey: #7f7f7f;
  --item-grey: #efefe6;
  --top-section-desktop-height: 233.5px;
  --top-section-mobile-height: 46px;
  --contrast-yellow: #f9df6d;
  --contrast-green: #a0c35a;
  --contrast-blue: #b0c4ef;
  --contrast-purple: #ba81c5;
  --group-0-color: var(--yellow);
  --group-1-color: var(--green);
  --group-2-color: var(--blue);
  --group-3-color: var(--purple);
}
.help-emojis > div {
  display: inline-flex;
  align-items: center;
  height: 20px;
}

.pz-row {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 12px;
}
.pz-row, .pz-col {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  min-height: 1px;
  flex: 1 1 0%;
}.pz-module {
  flex: 1 1 auto;
  margin: 12px;
}
.full-width-header {
  width: 100%;
}

.pz-game-title-bar h2 {
  display: flex;
  align-items: baseline;
}

.pz-game-title {
  font-size: 32px;
  font-weight: bold;
  margin-right: 16px;
}

.pz-game-date {
  font-size: 18px;
  font-weight: 300;
}

.pz-game-title-bar .pz-game-title {
  font-size: 32px;
  font-style: normal; 
  font-weight: bold;
  margin-right: 16px;
}

.pz-game-title-bar .pz-game-date {
  font-style: light;
  font-size: 18px;
  font-weight: 300;
}
/* Mobile-specific styles */
@media (max-width: 768px) {
  .mobile-header {
    border-top: none;
    height: auto; /* Auto-adjust the height */
    padding-bottom: 20px; /* Add padding to the bottom of the header */
  }

  .pz-game-title-bar h2 {
    flex-direction: column;
    align-items: flex-start;
  }

  .pz-game-title-bar .pz-game-date {
    display: block; /* Making it block level */
  }
}


@media (max-width: 768px), (max-height: 600px) {
  body {
    --game-spacing: 8px;
  }

  header {
    height: 3rem; /* Reduce header height */
  }

  h1 {
    font-size: 1rem; /* Reduce font size for header title */
  }

  .game-wrapper {
    flex: 1;
    gap: var(--game-spacing);
    padding: 8px; /* Reduce padding */
    min-width: 100%; /* Take full width */
    max-width: 100%; /* Take full width */
  }
}